<template>
  <div class="card card-custom gutter-b">
    <div
      :class="`alert alert-${message.color}`"
      role="alert"
      v-if="message.show"
    >
      {{ message.text }}
    </div>
    <div class="card-header flex-wrap py-3">
      <div class="card-title">
        <h3 class="card-label">
          Manage Engine Schema
          <span class="d-block text-muted pt-2 font-size-sm"
            >Add new fields or change the types of existing ones.</span
          >
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <v-flex class="mr-2">
          <collection_select />
        </v-flex>
        <v-flex>
          <model_dialog />
        </v-flex>
        <!--end::Button-->
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <div
        id="kt_datatable_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              :headers="headers"
              :items="schema_data"
              :options.sync="options"
              :loading="loading"
              :search="search"
              :item-class="itemRowBackground"
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <template v-slot:item.unique="{ item }">
                <span v-if="item.unique === '1'">
                  Yes
                </span>
              </template>
              <template v-slot:item.autocomplete="{ item }">
                <span v-if="item.autocomplete === '1'">
                  Yes
                </span>
              </template>
              <template v-slot:item.archive="{ item }">
                <span v-if="item.archive === '1'">
                  Yes
                </span>
              </template>
              <template v-slot:item.front_name="{ item }">
                <span v-if="item.front_name === '1'">
                  Yes
                </span>
              </template>
              <template v-slot:item.one_key="{ item }">
                <span v-if="item.one_key === '1'">
                  Yes
                </span>
                <span v-else>
                  No
                </span>
              </template>
              <template v-slot:top>
                <div class="d-flex align-items-center position-relative my-1">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="black"
                      ></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="search"
                    placeholder="Search in schema"
                    label="Search"
                    class="form-control form-control-solid w-250px ps-14"
                  />
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <b-dropdown text="Actions" v-if="!item.immutable">
                  <b-dropdown-item @click.prevent="editItem(item)">
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="uniqueItem(item)">
                    Use as Unique
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="frontItem(item)">
                    Use as Front Name
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import model_dialog from "./model_dialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PULL_SCHEMA,
  PULL_SCHEMAS,
  SET_SCHEMA_AS_FRONT,
  SET_SCHEMA_AS_UNIQUE
} from "@/core/services/store/schema.module";
import { mapGetters, mapState } from "vuex";
import collection_select from "@/view/pages/collections/collection_select";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import Swal from "sweetalert2";

export default {
  components: { model_dialog, collection_select },
  data() {
    return {
      options: {},
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Use for autocomplete", value: "autocomplete" },
        { text: "Prepare Type", value: "temp_type" },
        { text: "Unique", value: "unique" },
        { text: "Use for front", value: "front_name" },
        { text: "Array has one key", value: "one_key" },
        { text: "Archive", value: "archive" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapState({
      errors: state => state.schema.errors,
      message: state => state.message.message,
      schema_data: state => state.schema.schema_data,
      loading: state => state.schema.loading
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  methods: {
    itemRowBackground: function(item) {
      return item.archive === "1" ? "bg-light" : "";
    },
    editItem(item) {
      this.$store.dispatch(PULL_SCHEMA, item.id);
    },
    uniqueItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want change \`${item.term}\` as unique. All items will be deleted and recreated in storage up to 2hours. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(SET_SCHEMA_AS_UNIQUE, item.id).then(() => {
            this.reloadData();
            Swal.fire("Updated!", "", "success");
          });
        }
      });
    },
    frontItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want set \`${item.name}\` as front name of this project. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Set it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(SET_SCHEMA_AS_FRONT, item.id).then(() => {
            this.reloadData();
            Swal.fire("Updated!", "", "success");
          });
        }
      });
    },
    handleClick(row) {
      this.$store.dispatch(RELOAD_MESSAGE);
      this.$store.dispatch(PULL_SCHEMA, row.id);
    },
    reloadData() {
      if (this.selectedCollection && this.selectedCollection.id > 0) {
        this.$store.dispatch(PULL_SCHEMAS, this.selectedCollection.id);
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch(RELOAD_MESSAGE);
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Schema" }]);
  }
};
</script>
